import Toast, { ToastOptionTypes, ToastType } from "components/toast";
import { useMap } from "hooks";
import { BaseProviderType } from "models";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generateUID } from "utils";
import { Container, ToastProviderStyleType } from "./styles";

type CreateToastParams = Omit<ToastType, "removeMe">;

export type CreateToastType = (args: CreateToastParams) => void;

type ToastContextType = {
  success: CreateToastType;
  error: CreateToastType;
  warning: CreateToastType;
  info: CreateToastType;
  clearToasts: () => void;
};

type ToastProviderType = BaseProviderType & ToastProviderStyleType;

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

export const ToastProvider = ({ children, ...args }: ToastProviderType) => {
  const [toasts, { add, clear, remove }] = useMap<string, ToastType>();
  const { t } = useTranslation();

  const createToast = useMemo(
    () =>
      function (this: ToastOptionTypes, args: CreateToastParams): void {
        const id = generateUID();

        add(id, {
          ...args,
          type: this,
          removeMe: () => remove(id),
          title: args.title ?? t(this),
        });
      },
    [t, add, remove]
  );

  const success = useMemo(() => createToast.bind("success"), [createToast]);
  const error = useMemo(() => createToast.bind("error"), [createToast]);
  const warning = useMemo(() => createToast.bind("warning"), [createToast]);
  const info = useMemo(() => createToast.bind("info"), [createToast]);

  const clearToasts = useCallback(() => clear(), [clear]);

  return (
    <ToastContext.Provider
      value={{ success, error, warning, info, clearToasts }}
    >
      <Container {...args}>
        {Array.from(toasts ?? [])?.map(([id, props]) => (
          <Toast {...props} key={id} />
        ))}
      </Container>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
