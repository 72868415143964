import { Errors } from "services/errors";

export class VideoCallErrors extends Errors {
  connection = (reason: Error) => {
    switch (reason.name) {
      case "NotFoundError":
        this.warning({
          fixed: true,
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.inputDeviceNotFound"),
        });
        break;
      case "NotAllowedError":
        this.warning({
          fixed: true,
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.invalidWebcamAccess"),
        });
        break;
      case "TypeError":
        this.warning({
          fixed: true,
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.insecureAccess"),
        });
        break;
      case "NotReadableError":
        this.warning({
          fixed: true,
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.inputDeviceCompetition"),
        });
        break;
      case "OverconstrainedError":
        this.warning({
          fixed: true,
          title: this.t("alerts.accessDenied"),
          description: this.t("errors.interruptedAction"),
        });
        break;
      case "SignalingConnectionError":
      case "SignalingServerBusyError":
      case "RoomMaxParticipantsExceededError":
      case "RoomNotFoundError":
      case "MediaConnectionError":
      default:
        this.error({
          fixed: true,
          description: this.t("errors.connectionError"),
        });
        break;
    }
  };
}
